<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <organization-selector
        :organization="cafeteria.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            cafeteria.organization.id = organizationId;
            cafeteria.establishment.id = null;
            onFormChanged();
          }
        "
        :disabled="!!cafeteria.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <!-- establishment -->
    <div class="row"  v-if="cafeteria.organization.id != null">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ESTABLISHMENT')} (*)`"
          :placeholder="$t('COMMON.ESTABLISHMENT')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <establishment-selector
            :establishment="cafeteria.establishment.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="cafeteria.organization.id"
            @establishmentChanged="
              (establishmentId) => {
                cafeteria.establishment.id = establishmentId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.establishment" />
      </div>
    </div>
    
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="cafeteria.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>     
    </div>

    <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{
            cafeteria.id
              ? $t("CAFETERIAS.EDIT_CAFETERIA")
              : $t("CAFETERIAS.ADD_CAFETERIA")
          }}
        </base-button>
      </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
  },

  mixins: [formMixin],

  props: [
    "cafeteriaData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    const me = this.$store.getters["profile/me"];
    const cafeteriaData = { ...this.cafeteriaData };
    if (this.$currentUserIsLevelOrganization()) {
      cafeteriaData.organization.id = me.organization.id;
    }
    return {
      cafeteria: cafeteriaData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const cafeteriaData = cloneDeep(this.cafeteria);
      const me = this.$store.getters["profile/me"];
      this.$emit("cafeteriaSubmitted", cafeteriaData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    cafeteriaData(cafeteriaData) {
      if (cafeteriaData) {
        this.cafeteria = {
          ...this.cafeteria,
          ...cloneDeep(cafeteriaData),
        };
      }
    },
  },
};
</script>
