var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.cafeteria.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.cafeteria.id},on:{"organizationChanged":function (organizationId) {
          _vm.cafeteria.organization.id = organizationId;
          _vm.cafeteria.establishment.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.cafeteria.organization.id != null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ESTABLISHMENT')) + " (*)"),"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.cafeteria.establishment.id,"filterable":true,"showAll":false,"filterOrganization":_vm.cafeteria.organization.id},on:{"establishmentChanged":function (establishmentId) {
              _vm.cafeteria.establishment.id = establishmentId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"required":true},on:{"change":function () {
            _vm.onFormChanged();
          }},model:{value:(_vm.cafeteria.name),callback:function ($$v) {_vm.$set(_vm.cafeteria, "name", $$v)},expression:"cafeteria.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.cafeteria.id ? _vm.$t("CAFETERIAS.EDIT_CAFETERIA") : _vm.$t("CAFETERIAS.ADD_CAFETERIA"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }