export default {
  type: "cafeterias",
  name: null,
  relationshipNames: ["organization", "establishment"],
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  }
};
